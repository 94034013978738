import React from "react";

function Description({ description, text, innerHtml }) {
  return (
    <div>
      <h3>{description}</h3>
      <p>{text}</p>
      {innerHtml && (
        <div className="mb-1" dangerouslySetInnerHTML={{ __html: innerHtml }} />
      )}
    </div>
  );
}

export default Description;
