import React from "react";
import Description from "./fragments/description";
import Gallery from "./fragments/gallery";

function ImageMw({
  description,
  images,
  text,
  innerHtml,
  mergeBelow,
  optimizedImages,
  onGalleryImageClick
}) {
  const classes = mergeBelow === true ? "padding -mb-2" : "padding mb-12";
  return (
    <section className={classes} data-module="mw-image">
      <Description
        description={description}
        innerHtml={innerHtml}
        text={text}
      />
      <div className="max-w-3xl mx-auto">
        <div className="pt-2">
          <Gallery
            imageClasses={"pb-4"}
            images={images}
            onGalleryImageClick={onGalleryImageClick}
            optimizedImages={optimizedImages}
            withLightbox
          />
        </div>
      </div>
    </section>
  );
}

export default ImageMw;
