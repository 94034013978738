import React from "react";
import Description from "./fragments/description";
import Gallery from "./fragments/gallery";

function Image2c({
  description,
  images,
  text,
  innerHtml,
  mergeBelow,
  optimizedImages,
  onGalleryImageClick
}) {
  const classes = mergeBelow === true ? "padding -mb-2" : "padding mb-12";
  return (
    <section className={classes} data-module="2c-image">
      <Description
        description={description}
        innerHtml={innerHtml}
        text={text}
      />
      <div className="overflow-hidden">
        <div className="flex flex-wrap -mx-2">
          <Gallery
            imageClasses={"w-full px-2 py-2 sm:w-1/2"}
            images={images}
            onGalleryImageClick={onGalleryImageClick}
            optimizedImages={optimizedImages}
            withLightbox
          />
        </div>
      </div>
    </section>
  );
}

export default Image2c;
