import Description from "./fragments/description";
import React from "react";
import loadable from "@loadable/component";

function InnerHtml({
  body,
  description,
  text,
  mergeBelow,
  fileName,
  onGalleryImageClick
}) {
  const classes = mergeBelow === true ? "padding -mb-2" : "padding mb-12";
  const ContentFromFile = loadable(() => import(`../inner/${fileName}`));

  return (
    <section className={classes} data-module="inner-html">
      <Description description={description} text={text} />
      <div dangerouslySetInnerHTML={{ __html: body }} />
      {fileName && (
        <ContentFromFile onGalleryImageClick={onGalleryImageClick} />
      )}
    </section>
  );
}

export default InnerHtml;
