import React from "react";

function Summary({ title, subtitle, text, innerHtml }) {
  return (
    <section className="padding my-8" data-module="summary">
      <div>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        {text && <p className="mb-1">{text}</p>}
        {innerHtml && (
          <div
            className="mb-1"
            dangerouslySetInnerHTML={{ __html: innerHtml }}
          />
        )}
      </div>
    </section>
  );
}
export default Summary;
