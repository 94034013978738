import React from "react";
import Description from "./fragments/description";

function VideoModule({
  description,
  srcVideo,
  poster,
  innerHtml,
  text,
  mergeBelow
}) {
  const classes = mergeBelow === true ? "padding -mb-2" : "padding mb-12";
  return (
    <section className={classes} data-module="video">
      <Description
        description={description}
        innerHtml={innerHtml}
        text={text}
      />
      <video
        className="pt-2 mx-auto"
        controls="controls"
        poster={poster}
        width="600"
      >
        <source src={srcVideo} type="video/mp4" />
      </video>
    </section>
  );
}

export default VideoModule;
