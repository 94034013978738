import React, { PureComponent } from "react";
import Lightbox from "react-image-lightbox";

export default class LightBoxImages extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLightboxVisible: false,
      photoIndex: 0
    };
  }

  show(newIndex) {
    this.setState(() => ({
      isLightboxVisible: true,
      photoIndex: newIndex
    }));
  }

  render() {
    const { images, optimizedImages } = this.props;
    const { isLightboxVisible, photoIndex } = this.state;
    const numberOfImages = images.length;

    const currentImage = images[photoIndex];
    const nextImage = images[(photoIndex + 1) % numberOfImages];
    const prevImage =
      images[(photoIndex + numberOfImages - 1) % numberOfImages];
    return (
      <>
        {isLightboxVisible && (
          <Lightbox
            enableZoom={true}
            imageCaption={currentImage.captionLightbox}
            mainSrc={
              currentImage.static
                ? currentImage.src
                : optimizedImages[currentImage.src].childImageSharp.fluid.src
            }
            nextSrc={
              numberOfImages > 1
                ? nextImage.static
                  ? nextImage.src
                  : optimizedImages[nextImage.src].childImageSharp.fluid.src
                : undefined
            }
            onCloseRequest={() => {
              this.setState({ isLightboxVisible: false });
            }}
            onMoveNextRequest={() =>
              this.setState(prevState => ({
                photoIndex: (prevState.photoIndex + 1) % numberOfImages
              }))
            }
            onMovePrevRequest={() =>
              this.setState(prevState => ({
                photoIndex:
                  (prevState.photoIndex + numberOfImages - 1) % numberOfImages
              }))
            }
            prevSrc={
              numberOfImages > 1
                ? prevImage.static
                  ? prevImage.src
                  : optimizedImages[prevImage.src].childImageSharp.fluid.src
                : undefined
            }
          />
        )}
      </>
    );
  }
}
