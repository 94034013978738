import React, { useCallback, useRef } from "react";
import Layout from "../components/layout";
import BannerMain from "../components/modules/banner-main";
import Summary from "../components/modules/summary";
import Image1c from "../components/modules/image-1c";
import Image2c from "../components/modules/image-2c";
import Image3c from "../components/modules/image-3c";
import Image4c from "../components/modules/image-4c";
import ImageMw from "../components/modules/image-mw";
import Separator from "../components/modules/separator";
import VideoModule from "../components/modules/video";
import InnerHtml from "../components/modules/inner-html";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Image2c3 from "../components/modules/image-2c-3";
import Devices from "../components/modules/devices";
import { useMappedImages } from "../hooks/images";
import LightBoxImages from "../components/modules/fragments/lightbox-images";

const modules = {
  summary: Summary,
  "image-1c": Image1c,
  "image-2c-3": Image2c3,
  "image-2c": Image2c,
  "image-3c": Image3c,
  "image-4c": Image4c,
  "image-mw": ImageMw,
  separator: Separator,
  video: VideoModule,
  "inner-html": InnerHtml,
  devices: Devices
};

const Project = ({ data, pageContext: { slugPrevious, slugNext } }) => {
  const { projectsJson, allFile } = data;
  const { pageDetails } = projectsJson;
  const optimizedImages = useMappedImages(allFile);

  const imagesLightbox = pageDetails.modules
    .filter(
      module =>
        module.props &&
        module.props.images &&
        module.props.withoutLightbox !== true
    )
    .flatMap(modules => modules.props.images)
    .map(image => {
      return {
        src: image.src,
        static: image.static,
        captionLightbox: image.captionLightbox
      };
    });

  const imagesSrcLightboxArray = imagesLightbox.map(value => value.src);
  const lightBoxRef = useRef(null);
  const onGalleryImageClick = useCallback(
    imageSrc => {
      if (lightBoxRef.current) {
        lightBoxRef.current.show(imagesSrcLightboxArray.indexOf(imageSrc));
      }
    },
    [imagesSrcLightboxArray]
  );

  const bannerImages = [
    data.bannerMobile.childImageSharp.fluid,
    {
      ...data.bannerDesktop.childImageSharp.fluid,
      media: "(min-width: 425px)"
    }
  ];

  const bannerProps = {
    ...pageDetails.banner,
    images: bannerImages
  };

  return (
    <Layout>
      <SEO title={projectsJson.title} />
      <BannerMain {...bannerProps} />

      <main className="container max-w-6xl mx-auto mt-12">
        {pageDetails.modules &&
          pageDetails.modules.map((module, index) => {
            const SpecificModule = modules[module.moduleType];
            const moduleProps =
              {
                ...module.props,
                optimizedImages: optimizedImages,
                onGalleryImageClick
              } || {};
            return <SpecificModule {...moduleProps} key={`module-${index}`} />;
          })}
        <div className="my-10 flex flex-col">
          <Link
            className="link-projects m-auto mb-5 pb-1 font-semibold border-b border-dark"
            to="/#portfolio"
          >
            See all projects
          </Link>
          <div className="flex justify-center items-center">
            {slugPrevious !== undefined && slugNext !== undefined ? (
              <>
                <Link
                  className="cta-projects w-28 flex justify-center items-center"
                  to={slugPrevious}
                >
                  <span className="icon-arrow-l mr-2"></span>Previous
                </Link>
                <p className="my-0 mx-4 text-gray-400 text-xl">|</p>
                <Link
                  className="cta-projects w-28 flex justify-center items-center"
                  to={slugNext}
                >
                  Next<span className="icon-arrow-r ml-2"></span>
                </Link>
              </>
            ) : slugPrevious === undefined ? (
              <Link
                className="cta-projects w-28 flex justify-center items-center"
                to={slugNext}
              >
                Next<span className="icon-arrow-r ml-2"></span>
              </Link>
            ) : (
              <Link
                className="cta-projects w-28 flex justify-center items-center"
                to={slugPrevious}
              >
                <span className="icon-arrow-l mr-2"></span>Previous
              </Link>
            )}
          </div>
        </div>
      </main>
      <LightBoxImages
        images={imagesLightbox}
        optimizedImages={optimizedImages}
        ref={lightBoxRef}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query(
    $id: String!
    $imageRegexPath: String!
    $imageBannerRegexPath: String!
  ) {
    projectsJson(id: { eq: $id }) {
      title
      pageDetails {
        banner {
          src
          title
        }
        modules {
          moduleType
          props {
            body
            description
            withoutLightbox
            images {
              alt
              src
              title
              caption
              withBorder
              static
              captionLightbox
            }
            poster
            srcVideo
            isSwapped
            text
            title
            subtitle
            innerHtml
            srcImageMobile
            srcImageDesktop
            mergeBelow
            fileName
          }
        }
      }
    }
    allFile(filter: { relativePath: { regex: $imageRegexPath } }) {
      ...FluidImagesFragment
    }
    bannerMobile: file(relativePath: { regex: $imageBannerRegexPath }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 550, fit: COVER, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativePath
    }
    bannerDesktop: file(relativePath: { regex: $imageBannerRegexPath }) {
      ...FluidImageFragment
    }
  }
`;

export default Project;
