import React from "react";
import Description from "./fragments/description";
import Caption from "./fragments/caption";
import Img from "gatsby-image";

function Devices({
  description,
  text,
  caption,
  srcImageMobile,
  srcImageDesktop,
  innerHtml,
  mergeBelow,
  optimizedImages
}) {
  const classes = mergeBelow === true ? "padding -mb-2" : "padding mb-12";
  return (
    <section className={classes} data-module="devices">
      <Description
        description={description}
        innerHtml={innerHtml}
        text={text}
      />
      <Caption caption={caption} />

      <div className="pt-2">
        <div className="flex-wrap md:flex-no-wrap max-w-6xl flex items-center m-auto px-5 py-4">
          <div className="device-mobile m-auto md:pr-5">
            <div className="mobile-screen">
              {/* <div className="scroll-tip">
                <div className="scroll-arrow"></div>
              </div> */}
              <div className="scrollable">
                <Img
                  fluid={optimizedImages[srcImageMobile].childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
          <div className="device-desktop hidden sm:block w-full m-auto mt-10 md:mt-0">
            <div className="desktop-screen">
              {/* <div className="scroll-tip">
                <div className="scroll-arrow"></div>
              </div> */}
              <div className="scrollable">
                <Img
                  fluid={optimizedImages[srcImageDesktop].childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Devices;
