import { useMemo } from "react";

export const useMappedImages = allFile => {
  const optimizedImages = useMemo(
    () =>
      allFile.nodes.reduce((accumulator, currentVal) => {
        return { ...accumulator, [currentVal.relativePath]: currentVal };
      }, {}),
    [allFile.nodes]
  );

  return optimizedImages;
};
