import React from "react";
import Img from "gatsby-image";

function BannerMain({ src, images }) {
  return (
    <section
      className="mt-10 sm:mt-12 md:mt-10 pt-1 mb-6 md:pt-3"
      id="banner-main"
    >
      <div className="relative">
        <Img
          className="object-cover h-64 sm:h-72 md:h-80 lg:h-96 mx-auto md:w-full"
          fluid={images}
        />
      </div>
    </section>
  );
}

export default BannerMain;
