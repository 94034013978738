import React from "react";
import Description from "./fragments/description";
import Gallery from "./fragments/gallery";

function Image3c({
  description,
  images,
  text,
  innerHtml,
  mergeBelow,
  optimizedImages,
  onGalleryImageClick,
  withoutLightbox
}) {
  const classes = mergeBelow === true ? "padding -mb-2" : "padding mb-12";

  return (
    <section className={classes} data-module="3c-image">
      <Description
        description={description}
        innerHtml={innerHtml}
        text={text}
      />
      <div className="overflow-hidden">
        <div className="flex flex-wrap -mx-2">
          <Gallery
            arrayClasses={[images.length % 2 !== 0 ? "sm:w-full" : ""]}
            imageClasses={"w-full px-2 py-2 sm:w-1/2 md:w-1/3"}
            images={images}
            onGalleryImageClick={onGalleryImageClick}
            optimizedImages={optimizedImages}
            withLightbox={!withoutLightbox}
          />
        </div>
      </div>
    </section>
  );
}

export default Image3c;
