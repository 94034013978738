import React from "react";
import Description from "./fragments/description";
import Gallery from "./fragments/gallery";

function Image1c({
  description,
  text,
  images,
  innerHtml,
  mergeBelow,
  optimizedImages,
  onGalleryImageClick
}) {
  const classes = mergeBelow === true ? "padding -mb-2" : "padding mb-12";

  return (
    <section className={classes} data-module="1c-image">
      <Description
        description={description}
        innerHtml={innerHtml}
        text={text}
      />
      <div className="pt-2">
        <Gallery
          imageClasses={"w-full pb-4"}
          images={images}
          onGalleryImageClick={onGalleryImageClick}
          optimizedImages={optimizedImages}
          withLightbox
        />
      </div>
    </section>
  );
}

export default Image1c;
