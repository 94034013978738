import React from "react";
import Caption from "./caption";
import Img from "gatsby-image";

function Image({
  classes,
  classesImg,
  image: { title, alt, caption, withBorder },
  optimizedImage,
  onClick
}) {
  const classImgMerged =
    classesImg + (withBorder ? " border border-gray-300" : "");
  return (
    <div className={classes} onClick={onClick}>
      <Caption caption={caption} />
      <Img
        alt={alt}
        className={classImgMerged}
        fluid={optimizedImage.childImageSharp.fluid}
        title={title}
      />
    </div>
  );
}

function Gallery({
  images,
  imageClasses,
  arrayClasses,
  withLightbox,
  optimizedImages,
  onGalleryImageClick
}) {
  return (
    <>
      {images.map((image, index) => {
        const classesMerged =
          arrayClasses !== undefined && index < arrayClasses.length
            ? imageClasses + " " + arrayClasses[index]
            : imageClasses;
        const classesImgCursor = withLightbox ? " cursor-zoom-in" : "";
        const classesMergedWithCursor = classesMerged + classesImgCursor;
        const optimizedImage = optimizedImages[image.src];

        if (image.static) {
          return (
            <img
              className={classesMergedWithCursor}
              onClick={() => withLightbox && onGalleryImageClick(image.src)}
              src={image.src}
            />
          );
        }
        return (
          <Image
            classes={classesMerged}
            classesImg={classesImgCursor}
            image={image}
            key={`image-${index}`}
            onClick={() => withLightbox && onGalleryImageClick(image.src)}
            optimizedImage={optimizedImage}
          />
        );
      })}
    </>
  );
}

export default Gallery;
